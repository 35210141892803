<template>
  <div class="h-full w-screen">
    <PageSection
      reduced
      class="h-64"
    >
      <SectionText>
        Whether you are returning from an injury, are a more advanced gym-goer or are just looking for something new, let us help you reach your goals through our structured programming, encouraging atmosphere and welcoming community. 
      </SectionText>
      <SectionText>
        Our programming includes structured strength training, alternative conditioning, a wide range of core work and plenty of mobility and prehab to ensure you are progressing in all aspects of health and fitness.
      </SectionText>
      <button
        class="w-auto mt-4"
        @click="
          toggleModal({
            modalName: 'contact',
            subject: $route.meta.title,
            show: true,
          })
        "
      >
        <div
          class="bg-primary font-semibold text-black uppercase text-xs rounded-xl p-2 px-4"
          :aria-label="`Contact us about Return to training at UNIT 3 `"
        >
          Contact us about Return to training at UNIT 3 
        </div>
      </button>
    </PageSection>
    <LoadingState v-if="getTestimonialsIsLoading" />
    <ErrorState
      v-else-if="getTestimonialsIsError"
      :error="getTestimonialsError"
      :retry="fetchTestimonials"
    />

    <PageSection
      v-else
      title="Client testimonials"
      clas="flex flex-col "
    >
      <div
        :class="[
          'grid grid-cols-6 gap-12 pt-8 ',
        ]"
      >
        <div
          v-for="(athlete, index) in getTestimonials"
          :key="index"
          class="md:col-span-3 lg:col-span-3 col-span-6 flex flex-grow"
        >
          <Card
            bordered
            :background="'bg-opacity-75 bg-secondary-200'"
          >
            <template v-slot:subtitle>
              <p
                class="text-primary font-base leading-normal text-center"
                :class="[
                  'text-base',
                ]"
              >
                {{ athlete.athlete }}
              </p>
            </template>
            <template v-slot:title>
              <div
                class="flex flex-col gap-y-2 items-center justify-center mb-6"
              >
                <div
                  v-if="athlete.achievements"
                >
                  <p
                    v-for="achievement in athlete.achievements"
                    :key="achievement"
                    class="italic text-primary text-xs text-center"
                  >
                    {{ achievement }}
                  </p>
                </div>
              </div>
            </template>
            <template v-slot:body>
              <div class="border-l pl-4 border-secondary-DEFAULT">
                <BlogBlocks :content="athlete.comment" />
              </div>
            </template>
          </Card>
        </div>
      </div>
    </PageSection>
    <LoadingState v-if="getImagesIsLoading" />
    <ErrorState
      v-else-if="getImagesIsError"
      :error="getImagesError"
      :retry="fetchImages"
    />
    <Gallery
      v-else-if="getImages && getImages.length"
      :images="getImages"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ErrorState, LoadingState, PageSection, SectionText, Card, Gallery } from '@/pages/shared/index';
import testimonialStore from '@/infrastructure/store/modules/testimonials/';
import imagesStore from '@/infrastructure/store/modules/images/';
import BlogBlocks from '@/components/organisms/BlogBlocks/BlogBlocks.vue';

export default {
  name: 'ReturningPage',
  components: {
    ErrorState,
    LoadingState,
    PageSection,
    SectionText,
    Card,
    BlogBlocks,
    Gallery
  },
  computed: {
    ...mapGetters('images', [ 'getImages', 'getImagesIsError','getImagesError', 'getImagesIsLoading'  ]),
    ...mapGetters('testimonial', [ 'getTestimonials', 'getTestimonialsIsError','getTestimonialsError', 'getTestimonialsIsLoading'  ])
  },
  async created() {
    if(!this.$store.hasModule('images')) this.$store.registerModule('images', imagesStore);
    if(!this.$store.hasModule('testimonial')) this.$store.registerModule('testimonial', testimonialStore);
    await this.fetchImages('returning');
    await this.fetchTestimonials('returning');
  },
  methods: {
    ...mapActions([ 'toggleModal' ]),
    ...mapActions('testimonial', [ 'fetchTestimonials' ]),
    ...mapActions('images', [ 'fetchImages' ])
  }
};
</script>
